import React from 'react'
import {useTranslation} from 'react-i18next'

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ReplayIcon from '@mui/icons-material/Replay'
import {Box, FormControlLabel, IconButton} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import {FieldArrayRenderProps} from 'formik'
import isArray from 'lodash/isArray'
import {FormTextField as TextField, Switch, Typography} from 'ui-lib'

import DialogEmployeeSelect from '../DialogEmployeeSelect'
import EmployeeSelectedOption from '../DialogEmployeeSelect/EmployeeSelectedOption'
import OrderControls from '../OrderControls'
import ServiceConfigCard from '../ServiceConfigCard'

import searchApi from 'API/search'
import {ServicesEnum} from 'constants/services'
import {ServiceGroupItem} from 'pages/Company/CompanyForm/types'
import {IDialogOption, ServiceItem} from 'pages/Company/types'
import {FieldTypes, Id} from 'types'

import useServiceCardStyles from './styles'

interface ServiceCardProps {
  service: ServiceGroupItem
  serviceIndex: number
  serviceInGroupIndex?: number
  arrayHelpers: FieldArrayRenderProps
  values: ServiceGroupItem[]
  // TODO uncomment this fields after completing KT2
  // isReferenceType: boolean
  // errorTemplateFile?: string
}

const hrsMax = 50

const ServiceCard: React.FC<ServiceCardProps> = ({
  service,
  serviceIndex,
  serviceInGroupIndex,
  arrayHelpers,
  values,
  // TODO uncomment this fields after completing KT2
  // isReferenceType,
  // errorTemplateFile,
}) => {
  const classes = useServiceCardStyles()
  const {t} = useTranslation()
  const index = service.isGroup && serviceInGroupIndex !== undefined ? serviceInGroupIndex : serviceIndex
  const sequenceIndex = index + 1
  const showHrsNotNeeded = service.isActive && !service.isHrsRequired
  const hrsCount = service?.hrs?.length
  const isHrsMax = !!(hrsCount && hrsCount >= hrsMax)
  const selectedHrsIds = service?.hrs?.map(hr => hr.id)
  const isInGroup = service.isGroup
  const isExternalService = service.hrServiceType === ServicesEnum.EXTERNAL
  const canChangeSomeOption =
    service.isCheckListEnabled !== undefined ||
    service.isDocumentSignViaDijaEnabled !== undefined ||
    service.isLimitedAccess !== undefined ||
    service.isDismissalRequestEnabled !== undefined ||
    service.isEnableReceivingMethod !== undefined ||
    service?.receivingMethods?.length

  const canSeeSettings = !isExternalService && canChangeSomeOption
  const canResetName = service.name !== service.originalName
  const canResetEnglishName = service.englishName !== service.originalEnglishName

  const handleChangeIsActive = (e: React.BaseSyntheticEvent) => {
    arrayHelpers.replace(index, {...values[index], isActive: e.target.checked})
  }

  const handleAddHrs = (value?: IDialogOption[]) => {
    const newValue = value?.[0]

    arrayHelpers.replace(index, {
      ...values[index],
      hrs: [...(values?.[index].hrs || []), newValue],
    })
  }

  const handleDeleteHrs = (id?: number) => {
    if (id) {
      const filteredHrs = values?.[index].hrs.filter(hr => hr.id !== id)

      arrayHelpers.replace(index, {
        ...values?.[index],
        hrs: [...filteredHrs],
      })
    }
  }

  const handleDeleteService = () => {
    arrayHelpers.remove(index)
  }

  const updateService = (fieldType: FieldTypes, serviceItem?: ServiceItem) => {
    if (serviceItem) {
      const isActiveFieldType = fieldType === 'isActive'
      const isLimitedAccessFieldType = fieldType === 'isLimitedAccess'
      const isCheckListEnabledFieldType = fieldType === 'isCheckListEnabled'
      const isDismissalRequestEnabledFieldType = fieldType === 'isDismissalRequestEnabled'
      const isDocumentSignViaDijaEnabledFieldType = fieldType === 'isDocumentSignViaDijaEnabled'
      const isEnableCompensationEnabledFieldType = fieldType === 'isEnableCompensation'
      const isEnableAddressReceivingMethod = fieldType === 'isEnableAddressReceivingMethod'
      const isEnableDigitalReceivingMethod = fieldType === 'isEnableDigitalReceivingMethod'
      // TODO uncomment this field after completing KT2
      // const isEnableEReferenceReceivingMethod = fieldType === 'isEnableEReferenceReceivingMethod'

      return {
        ...serviceItem,
        isActive: isActiveFieldType ? !serviceItem.isActive : serviceItem.isActive,
        isLimitedAccess: isLimitedAccessFieldType ? !serviceItem.isLimitedAccess : serviceItem.isLimitedAccess,
        isCheckListEnabled: isCheckListEnabledFieldType
          ? !serviceItem.isCheckListEnabled
          : serviceItem.isCheckListEnabled,
        isDismissalRequestEnabled: isDismissalRequestEnabledFieldType
          ? !serviceItem.isDismissalRequestEnabled
          : serviceItem.isDismissalRequestEnabled,
        isDocumentSignViaDijaEnabled: isDocumentSignViaDijaEnabledFieldType
          ? !serviceItem.isDocumentSignViaDijaEnabled
          : serviceItem.isDocumentSignViaDijaEnabled,
        isEnableCompensation: isEnableCompensationEnabledFieldType
          ? !serviceItem.isEnableCompensation
          : serviceItem.isEnableCompensation,

        isEnableAddressReceivingMethod: isEnableAddressReceivingMethod
          ? !serviceItem.isEnableAddressReceivingMethod
          : serviceItem.isEnableAddressReceivingMethod,
        isEnableDigitalReceivingMethod: isEnableDigitalReceivingMethod
          ? !serviceItem.isEnableDigitalReceivingMethod
          : serviceItem.isEnableDigitalReceivingMethod,
        // TODO uncomment this field after completing KT2
        // isEnableEReferenceReceivingMethod: isEnableEReferenceReceivingMethod
        //   ? !serviceItem.isEnableEReferenceReceivingMethod
        //   : serviceItem.isEnableEReferenceReceivingMethod,
      }
    }
    return undefined
  }

  const handleSwitchConfigurations = (serviceId: Id, fieldType: FieldTypes) => {
    if (!isArray(values)) return
    const serviceToUpdate = values.find(serviceItem => serviceItem.id === serviceId && !serviceItem.serviceFromGroup)
    const updatedService = updateService(fieldType, serviceToUpdate)

    arrayHelpers.replace(index, {...updatedService})
  }

  const handleResetToOriginalName = () => {
    arrayHelpers.replace(index, {
      ...values?.[index],
      name: service.originalName,
    })
  }

  const handleResetToOriginalEnglishName = () => {
    arrayHelpers.replace(index, {
      ...values?.[index],
      englishName: service.originalEnglishName,
    })
  }

  const handleMoveToTopSequence = () => {
    if (sequenceIndex !== 1) {
      const indexTo = index - 1

      arrayHelpers.move(index, indexTo)
    }
  }

  const handleMoveToBottomSequence = () => {
    if (index < values.length - 1) {
      const indexTo = index + 1

      arrayHelpers.move(index, indexTo)
    }
  }

  return (
    <Box className={classes.container} data-test={`service-card-${service.originalName}`}>
      <OrderControls
        handleMoveToTopSequence={handleMoveToTopSequence}
        handleMoveToBottomSequence={handleMoveToBottomSequence}
        isInGroup={isInGroup}
        serviceIndex={serviceIndex}
        serviceInGroupIndex={serviceInGroupIndex}
        valuesLength={values.length}
      />

      <Box className={classes.cardWrapper}>
        <Box className={classes.switchBox}>
          {!isExternalService && (
            <FormControlLabel
              classes={{label: classes.switchControlLabel, root: classes.myFormControlLabelRoot}}
              control={
                <Switch
                  name={
                    isInGroup
                      ? `services.${serviceIndex}.serviceFromGroup.${serviceInGroupIndex}.isActive`
                      : `services.${index}.isActive`
                  }
                  data-test="is-active"
                  isUpdating={false}
                  checked={!!service.isActive}
                  onChange={handleChangeIsActive}
                />
              }
              label=""
              data-test="is-archived"
            />
          )}
          <Typography variant="h2">{service.originalName}</Typography>

          <IconButton
            onClick={e => {
              e.stopPropagation()
              handleDeleteService()
            }}
            className={classes.icon}
            aria-label="delete"
            data-test="delete-service"
            component="label"
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Box>

        <Box className={classes.inputWrapper}>
          <TextField
            name={
              isInGroup
                ? `services.${serviceIndex}.serviceFromGroup.${serviceInGroupIndex}.name`
                : `services.${index}.name`
            }
            label={t('create-company.name')}
            inputProps={{
              maxLength: 256,
              'data-test': 'name',
            }}
            helperText={t('validation.required')}
          />

          <IconButton
            className={classes.resetIcon}
            data-test="reset-input-name"
            onClick={handleResetToOriginalName}
            edge="end"
            disabled={!canResetName}
          >
            <ReplayIcon />
          </IconButton>
        </Box>

        <Box className={classes.inputWrapper}>
          <TextField
            name={
              isInGroup
                ? `services.${serviceIndex}.serviceFromGroup.${serviceInGroupIndex}.englishName`
                : `services.${index}.englishName`
            }
            label={t('create-company.english-name')}
            inputProps={{maxLength: 256, 'data-test': 'english-name'}}
            helperText={t('validation.required')}
          />
          <IconButton
            className={classes.resetIcon}
            data-test="reset-input-en-name"
            onClick={handleResetToOriginalEnglishName}
            edge="end"
            disabled={!canResetEnglishName}
          >
            <ReplayIcon />
          </IconButton>
        </Box>

        <Box className={classes.companyControlsRow}>
          {!!canSeeSettings && (
            <ServiceConfigCard
              key={service?.id}
              serviceItem={service}
              handleSwitch={handleSwitchConfigurations}
              namePrefix={
                isInGroup ? `services.${serviceIndex}.serviceFromGroup.${serviceInGroupIndex}` : `services.${index}`
              }
            />
          )}
          {/* TODO uncomment this fields after completing KT2 */}
          {/* {isReferenceType && (
            <>
              <UploadingFile
                inputName={
                  isInGroup
                    ? `services.${serviceIndex}.serviceFromGroup.${serviceInGroupIndex}.templateFile`
                    : `services.${index}.templateFile`
                }
                uploadButtonText={t('create-company.template-file')}
                error={
                  service.isActive && !service?.templateFile?.length && errorTemplateFile
                    ? t('validation.required')
                    : ''
                }
                accept=".docx,.doc"
                maxSizeInMBytes={5}
                fileTypes={['application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                fileViewProps={{canSeeDownloadIcon: true, canSeeViewIcon: false}}
              />

              <UploadingFile
                inputName={
                  isInGroup
                    ? `services.${serviceIndex}.serviceFromGroup.${serviceInGroupIndex}.stampFile`
                    : `services.${index}.stampFile`
                }
                uploadButtonText={t('create-company.stamp-file')}
                accept=".png,.svg"
                fileSizeInPx={{width: 200, height: 200}}
                validateInPxMessage="upload-file.file-size-px-company-stamp"
                maxSizeInMBytes={5}
                fileTypes={['image/png', 'image/svg', 'image/svg+xml']}
                fileViewProps={{canSeeDownloadIcon: true}}
              />
            </>
          )} */}
          {service.isHrsRequired && !isExternalService && (
            <Tooltip title={isHrsMax && t('administration.add-hr-tooltip')}>
              <Box>
                <DialogEmployeeSelect
                  selectInfo={{
                    title: t('create-company.select-hr-manager-subtitle'),
                    description: t('create-company.select-hr-manager-description'),
                    headerTitle: t('create-company.select-hr-manager-title'),
                    inputName: '',
                    inputPlaceholder: t('create-company.select-hr-manager-input-placeholder'),
                    selectPlaceholder: t('create-company.select-hr-manager-name'),
                    request: searchApi.getAdminEmployees,
                  }}
                  values={service.hrs}
                  onChange={handleAddHrs}
                  onDelete={handleDeleteHrs}
                  SelectedOptionComponent={EmployeeSelectedOption}
                  disabled={isHrsMax}
                  selectedDisabledIds={selectedHrsIds}
                  multiple
                  disabledHover
                />
              </Box>
            </Tooltip>
          )}
          {showHrsNotNeeded && (
            <Box className={clsx(classes.companyControlsCol, classes.companyHrsNotRequired)}>
              <Typography variant="subtitle2" data-test="employee-name">
                {t('administration.hrs-not-required')}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ServiceCard
