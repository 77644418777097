import * as Yup from 'yup'

const serviceItemSchema: any = Yup.lazy(() =>
  Yup.object().shape({
    isActive: Yup.boolean().nullable(),
    name: Yup.string().required('validation.required'),
    englishName: Yup.string().required('validation.required'),
    serviceFromGroup: Yup.array().of(serviceItemSchema),
    hrServiceType: Yup.string().max(256).nullable(),
    templateFile: Yup.array().nullable(),
    // TODO uncomment this validation method after completing KT2
    // .when(['isActive', 'hrServiceType'], {
    //   is: (isActive: boolean, hrServiceType: ServicesEnum) => hrServiceType === ServicesEnum.REFERENCES && isActive,
    //   then: schema => schema.min(1).required('validation.required'),
    //   otherwise: schema => schema,
    // }),
    stampFile: Yup.array().nullable(),
  }),
)

export const validationFormSchema = Yup.object().shape({
  logo: Yup.array().nullable().min(1).required('validation.required'),
  services: Yup.array().of(serviceItemSchema).required('validation.required'),
})
